@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* React Flow */
.graph .react-flow__controls-button {
  background: none;
  border-bottom: none;
  border-style: solid;
  @apply inline-flex bg-white border border-gray-300 p-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500;
}

.graph .react-flow__controls-button svg {
  fill: currentColor;
  @apply text-gray-500;
}

.graph .react-flow__controls-button:first-child {
  @apply rounded-l-md;
}

.graph .react-flow__controls-button:not(:first-child) {
  @apply -ml-px;
}

.graph .react-flow__controls-button:last-child {
  @apply rounded-r-md;
}

.graph .react-flow__edge-path {
  @apply stroke-gray-300;
}

.react-flow__edge.animated .react-flow__edge-path {
  @apply stroke-green-300;
}

.graph .react-flow__edge.selected .react-flow__edge-path,
.graph .react-flow__edge:focus .react-flow__edge-path,
.graph .react-flow__edge:focus-visible .react-flow__edge-path {
  @apply stroke-indigo-500;
}

.graph .react-flow__nodesselection-rect {
  transform: scale(1.3);
  @apply border-indigo-600;
}

.react-flow__edge-path-selector {
  fill: none;
  stroke: transparent;
  stroke-width: 14;
}

.react-flow__handle-valid > div {
  @apply !border-green-300;
}

.react-flow__edge.animated foreignObject path {
  animation: none;
  stroke-dasharray: 0;
}

.react-flow__attribution {
  z-index: 1 !important;
}